const MAIN = '/sign-up';
const SHOP = '/sign-up/shop';
const ADDRESS = '/sign-up/address';
const CONTACT = '/sign-up/contact';
const CONFIRM = '/sign-up/confirm';
const SUCCESS = '/sign-up/success';
const ERROR = '/sign-up/error';

export default {
  MAIN,
  CONTACT,
  ADDRESS,
  SHOP,
  SUCCESS,
  ERROR,
  CONFIRM,
};
