import React from 'react';
import classNames from 'classnames';
import { navigate } from 'gatsby';

import StepModel from '@heureca/shared/models/Step.model';

import Button from '../Button';
import * as styles from './step.module.scss';

interface Props {
  step: StepModel;
  index: number;
  selected: boolean;
  disabled?: boolean;
}

const Step = (props: Props) => {
  const {
    step,
    index,
    selected,
    disabled = false,
  } = props;

  const onStepClick = () => {
    navigate(step.route);
  };

  return (
    <Button
      onClick={onStepClick}
      type="button"
      disabled={disabled}
      classNames={classNames(styles.container, { [styles.disabled]: disabled })}
    >
      <div className={classNames(styles.step, { [styles.selected]: selected })}>
        {index}
      </div>
      <span className={classNames(styles.name, { [styles.disabled]: disabled })}>
        {step.name}
      </span>
    </Button>
  );
};

export default Step;
