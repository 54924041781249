/* eslint-disable react/jsx-props-no-spreading */
import React, { ComponentType } from 'react';

import SignUpProvider from './SignUpProvider';

function HocSignUp<P>(step: string) {
  return (component: ComponentType<P>) => {
    const Component = component;

    return (props: P) => (
      <SignUpProvider step={step}>
        <Component {...props} />
      </SignUpProvider>
    );
  };
}

export default HocSignUp;
