import React, { useState } from 'react';
import { navigate } from 'gatsby';

import useSignUp from '@heureca/signUp/hooks/useSignUp.hook';
import Layout from '@heureca/shared/components/Layout';
import useTranslations from '@heureca/shared/hooks/useTranslations.hook';
import Steps from '@heureca/shared/components/Steps';
import Button from '@heureca/shared/components/Button';
import signUpServices from '@heureca/signUp/services/signUp.services';
import routeConstants from '@heureca/signUp/constants/route.constants';
import analyticsUtils from '@heureca/shared/utils/analytics.utils';
import recurrenceConstants from '@heureca/signUp/constants/subscriptionRecurrence.constants';
import pricingConstants from '@heureca/pricing/constants/pricing.constants';

import SignUpSectionCard from '../SignUpSectionCard';

import * as styles from './confirmStep.module.scss';

const ConfirmStep = () => {
  const { translate } = useTranslations();
  const { signUp } = useSignUp();
  const [isSubmitted, setIsSubmitted] = useState(false);

  if (!signUp.initialized) {
    return null;
  }

  const {
    type,
    recurrence,
  } = signUp.subscription.value;

  const {
    name,
    routeName,
  } = signUp.shop.value;

  const {
    street,
    streetNumber,
    box,
    locality,
    postCode,
    country,
  } = signUp.address.value;

  const {
    managerFirstName,
    managerLastName,
    email,
    phone,
  } = signUp.contact.value;

  const submit = async () => {
    setIsSubmitted(true);

    analyticsUtils.addEvent({
      event: 'Confirmation step completed',
    });

    const mappedData = {
      subscriptionType: signUp.subscription.value.type,
      subscriptionRecurrence: signUp.subscription.value.recurrence,
      ...signUp.shop.value,
      ...signUp.contact.value,
      address: signUp.address.value,
    };

    try {
      await signUpServices.createRegistration(mappedData);
      navigate(routeConstants.SUCCESS);
    } catch (error) {
      setIsSubmitted(false);
      navigate(routeConstants.ERROR);
    }
  };

  return (
    <Layout pageTitle={translate('signUp.title')} isSignUp>
      <SignUpSectionCard
        name="confirm-data"
        title={translate('signUp.steps.confirm')}
      >
        <Steps currentStep={4} />

        <div className={styles.infos}>
          <div className={styles.info}>
            <h3>{translate('signUp.steps.subscription')}</h3>
            <p>{`${translate('signUp.subscription')} : ${translate(`pricing.plan.${type}`)}`}</p>
            <>
              {recurrence === recurrenceConstants.YEARLY
                && (
                  <>
                    <p>{translate('signUp.yearlySubscription')}</p>
                    <p>{translate('signUp.yearlyInvoiceDetails')}</p>
                  </>
                )}

              {recurrence === recurrenceConstants.MONTHLY
                && (
                  <>
                    <p>{translate('signUp.monthlySubscription')}</p>
                    <p>{translate('signUp.monthlyInvoiceDetails')}</p>
                  </>
                )}
            </>
          </div>
          <div className={styles.info}>
            <h3>{translate('signUp.steps.shop')}</h3>
            <p>{`${translate('signUp.name')} : ${name}`}</p>
            <p>{`${translate('signUp.website')} : https://${routeName}.myheureca.com`}</p>
          </div>
          <div className={styles.info}>
            <h3>{translate('signUp.steps.address')}</h3>
            <p>{`${street} ${streetNumber} ${box ? `(${box})` : ''}`}</p>
            <p>{`${postCode} ${locality} (${country})`}</p>
          </div>
          <div className={styles.info}>
            <h3>{translate('signUp.steps.contact')}</h3>
            <p>{`${managerFirstName} ${managerLastName}`}</p>
            <p>{`${phone} - ${email}`}</p>
          </div>
          <hr className={styles.cgu} />
          <div className={styles.cgu}>
            <p>
              {translate('signUp.cgu.text')}
              <a href="/conditions" target="_blank" rel="noreferrer">
                {translate('signUp.cgu.conditions')}
              </a>
            </p>
          </div>
        </div>

        <div className={styles.buttonWrapper}>
          <Button
            label={translate('signUp.confirmSubscription')}
            onClick={submit}
            disabled={isSubmitted}
          />
        </div>

      </SignUpSectionCard>
    </Layout>
  );
};

export default ConfirmStep;
