import ApiError from '../models/ApiError.model';

const makeThrowError = (errorMapper: (apiError: ApiError) => string) => (error: any) => {
  if (error && error.response) {
    const { data, status } = error.response;
    const { title, data: errorData } = data;

    if ((title || errorData) && status) {
      throw new Error(errorMapper({ title, status, data: errorData }));
    }
  }

  throw new Error('Une erreur inconnue est survenue');
};

export default makeThrowError;
