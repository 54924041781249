import React, { PropsWithChildren } from 'react';

import Card from '@heureca/shared/components/Card';
import Section from '@heureca/shared/components/Section';

import * as styles from './signUpSectionCard.module.scss';

interface Props {
  name: string;
  title: string;
}

const SignUpSectionCard = (props: PropsWithChildren<Props>) => {
  const { children, name, title } = props;

  return (
    <Section
      name={name}
      title={title}
      containerClassNames={styles.content}
    >
      <Card cardClassNames={styles.card}>
        {children}
      </Card>
    </Section>
  );
};

export default SignUpSectionCard;
