import AnalyticsPayload from '../models/Analytics.model';

declare global {
  interface Window {
    gtag: (
      type: string,
      event: string,
      payload: { [key: string]: unknown }
    ) => void;
  }
}

const addEvent = (payload: AnalyticsPayload) => {
  if (typeof window !== 'undefined') {
    try {
      window.gtag('event', 'click', { ...payload });
    } catch (error) {
      console.log(error);
    }
  }
};

export default {
  addEvent,
};
