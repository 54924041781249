import {
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';

import SignUpContext from '../components/SignUpContext';
import SignUp from '../models/SignUp.model';

const useSignUp = (onUpdateFinished?: () => void) => {
  const { signUp, updateSignUp: update, reset } = useContext(SignUpContext);
  const wasUpdated = useRef(false);

  const updateSignUp = useCallback((signUpUpdate: Partial<SignUp>) => {
    update(signUpUpdate);
    wasUpdated.current = true;
  }, [update]);

  useEffect(() => {
    if (wasUpdated.current) {
      wasUpdated.current = false;

      if (onUpdateFinished) {
        onUpdateFinished();
      }
    }
  }, [signUp]);

  return {
    signUp,
    updateSignUp,
    reset,
  };
};

export default useSignUp;
