import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { navigate } from 'gatsby';
import { StringParam, useQueryParam } from 'use-query-params';

import pricingConstants from '@heureca/pricing/constants/pricing.constants';

import SignUp from '../models/SignUp.model';
import SignUpContext, { defaultValue } from './SignUpContext';
import routeConstants from '../constants/route.constants';
import stepConstants from '../constants/step.constants';

interface Props {
  step: string;
}

const prices = [pricingConstants.PRO];

const SignUpProvider = (props: PropsWithChildren<Props>) => {
  const { children, step } = props;
  const [signUp, setSignUp] = useState<SignUp>(defaultValue.signUp);

  const [priceId] = useQueryParam('priceId', StringParam);
  const [recurrenceId] = useQueryParam('recurrenceId', StringParam);

  const updateSignUp = useCallback((signUpUpdate: Partial<SignUp>) => {
    const signUpToUpdate = {
      ...signUp,
      ...signUpUpdate,
    };

    setSignUp(signUpToUpdate);
    const { initialized, ...signUptmp } = signUpToUpdate;
    sessionStorage.setItem('signUp', JSON.stringify(signUptmp));
  }, [signUp, setSignUp]);

  const getSubcription = (price: string, recurrence: string) => {
    if (price && recurrence) {
      const value = { type: price, recurrence };
      return { valid: true, value };
    }

    return { valid: false };
  };

  useEffect(() => {
    const storageOrder = sessionStorage.getItem('signUp');
    const mustResetSteps = step !== stepConstants.SIGN_UP
      && step !== stepConstants.SUCCESS
      && step !== stepConstants.ERROR;

    if (priceId && !prices.includes(priceId)) {
      navigate(routeConstants.MAIN);
      return;
    }

    let signUpToRestore = { ...defaultValue.signUp };

    if (storageOrder) {
      signUpToRestore = JSON.parse(storageOrder);
    } else if (
      step === stepConstants.SHOP
      && priceId
      && recurrenceId
    ) {
      signUpToRestore = {
        ...signUpToRestore,
        subscription: getSubcription(priceId, recurrenceId),
      };
    } else if (mustResetSteps) {
      navigate(routeConstants.MAIN);

      return;
    }

    signUpToRestore.initialized = true;
    setSignUp(signUpToRestore);
  }, []);

  useEffect(() => {
    if (!priceId && !recurrenceId) {
      return;
    }

    const recurrence = signUp.subscription.value?.recurrence;
    const type = signUp.subscription.value?.type;

    updateSignUp({
      subscription: {
        valid: true,
        value: { recurrence: recurrenceId || recurrence, type: priceId || type },
      },
    });
  }, [priceId, recurrenceId]);

  const reset = () => {
    sessionStorage.removeItem('signUp');
    setSignUp(defaultValue.signUp);
  };

  return (
    <SignUpContext.Provider value={{
      signUp,
      updateSignUp,
      reset,
    }}
    >
      {children}
    </SignUpContext.Provider>
  );
};

export default SignUpProvider;
