import { createContext } from 'react';
import SignUp from '../models/SignUp.model';

interface SignUpContextValue {
  signUp: SignUp;
  updateSignUp: (signUpUpdate: Partial<SignUp>) => void;
  reset: () => void;
}

const signUp = {
  subscription: { valid: false },
  shop: { valid: false },
  address: { valid: false },
  contact: { valid: false },
  initialized: false,
};

export const defaultValue: SignUpContextValue = {
  signUp,
  updateSignUp: () => { },
  reset: () => { },
};

const SignUpContext = createContext(defaultValue);

export default SignUpContext;
