const SIGN_UP = 'sign-up';
const SHOP = 'shop';
const SUCCESS = 'success';
const ERROR = 'error';
const ADDRESS = 'address';
const CONTACT = 'contact';
const CONFIRM = 'confirm';

export default {
  SHOP,
  SIGN_UP,
  SUCCESS,
  ERROR,
  ADDRESS,
  CONTACT,
  CONFIRM,
};
