import axios from 'axios';

import Queue from '../utils/queue.utils';
import config from '../../../../config';

type Method = 'post' | 'get';

const createHeaders = (additionalHeaders: any) => {
  const httpHeaders = {
    'Content-Type': 'application/json',
    ...additionalHeaders,
  };

  return httpHeaders;
};

const request = (
  endpoint: string,
  method: Method,
  body?: any,
  additionalHeaders?: any,
): Promise<any> => {
  const header = createHeaders(additionalHeaders);

  return new Promise((resolve, reject) => config(
    `${endpoint}`,
    {
      method,
      data: body,
      headers: header,
    },
  ).then((response) => {
    // console.log('Response', response);
    resolve(response.data);
  })
    .catch((error) => {
      if (!axios.isCancel(error)) {
        // console.log('Error', error);
      }
      if (error.code === 'ECONNABORTED') {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({ isTimeout: true });
        return;
      }
      reject(error);
    }));
};

const exec = (
  endpoint: string,
  method: Method,
  body?: any,
  additionalHeaders?: any,
): Promise<any> => Queue.enqueue(() => request(endpoint, method, body, additionalHeaders));

const get = (endpoint: string) => exec(endpoint, 'get');
const post = (endpoint: string, body: any) => exec(endpoint, 'post', body);

export default {
  get,
  post,
};
