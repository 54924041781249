import React from 'react';

import StepModel from '@heureca/shared/models/Step.model';
import routeConstants from '@heureca/signUp/constants/route.constants';
import useTranslations from '@heureca/shared/hooks/useTranslations.hook';

import Step from '../Step';

import * as styles from './steps.module.scss';

interface Props {
  currentStep: number;
}

const Steps = (props: Props) => {
  const { currentStep } = props;
  const { translate } = useTranslations();

  const steps: StepModel[] = [
    {
      id: 1,
      name: translate('signUp.steps.shop'),
      route: routeConstants.SHOP,
    },
    {
      id: 2,
      name: translate('signUp.steps.address'),
      route: routeConstants.ADDRESS,
    },
    {
      id: 3,
      name: translate('signUp.steps.contact'),
      route: routeConstants.CONTACT,
    },
    {
      id: 4,
      name: translate('signUp.steps.confirm'),
      route: routeConstants.CONFIRM,
    },
  ];

  return (
    <div className={styles.container}>
      {steps.map((s, index) => (
        <Step
          key={s.id}
          step={s}
          index={index + 1}
          selected={currentStep === index + 1}
          disabled={currentStep < index + 1}
        />
      ))}
    </div>
  );
};

export default Steps;
